import cover from './cover.png';
import './ThePowersOfTwo.css';

const ThePowersOfTwo = () => {
    return <div className="album">
        <img src={cover} alt="Album cover" />
        <h2>The Powers of Two</h2>
        <h3>Now available on:</h3>
        <div className="availability-list">
            <a href="https://open.spotify.com/album/5nzqBeEjiX7m9seiYYiD5P?si=fVYagRCqQpeRKV21jmaESQ"><span>Spotify</span></a>
            <a href="https://music.youtube.com/playlist?list=OLAK5uy_ncwP6XlLaR142K4dB6AcRg_uCqozFc0UY&feature=share"><span>Youtube Music</span></a>
            <a href="https://music.apple.com/us/album/the-powers-of-two/1638608948"><span>Apple Music</span></a>
        </div>
    </div>;
}

export default ThePowersOfTwo;