import { library } from '@fortawesome/fontawesome-svg-core';
import { faApple, faFacebook, faInstagram, faSpotify, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Links.css';

library.add(faSpotify);
library.add(faYoutube);
library.add(faApple);
library.add(faFacebook);
library.add(faTwitter);
library.add(faInstagram);

const Links = () => {
    return <div className="links">
        <a href="https://open.spotify.com/artist/5Y4WgWBWAlINs00nixVlGO?si=x0uwCCAmRxmKskLUMUY38w"
            className="link-spotify"
            target="_blank">
            <FontAwesomeIcon icon={[ 'fab', 'spotify' ]} /><span>Spotify</span>
        </a>
        <a href="https://music.youtube.com/channel/UChPWGckjAYRpuAFRx0D8GVQ?feature=share"
            className="link-youtube"
            target="_blank">
            <FontAwesomeIcon icon={[ 'fab', 'youtube' ]} /><span>Youtube Music</span>
        </a>
        <a href="https://music.apple.com/us/artist/karacasoft/1497472542"
            className="link-apple"
            target="_blank">
            <FontAwesomeIcon icon={[ 'fab', 'apple' ]} /><span>Apple Music</span>
        </a>
        <a href="https://www.facebook.com/iamkaracasoft"
            className="link-facebook"
            target="_blank">
            <FontAwesomeIcon icon={[ 'fab', 'facebook' ]} /><span>Facebook</span>
        </a>
        <a href="https://twitter.com/_KaracaSoft"
            className="link-twitter"
            target="_blank">
            <FontAwesomeIcon icon={[ 'fab', 'twitter' ]} /><span>Twitter</span>
        </a>
        <a href="https://www.instagram.com/mr.karacasoft/"
            className="link-instagram"
            target="_blank">
            <FontAwesomeIcon icon={[ 'fab', 'instagram' ]} /><span>Instagram</span>
        </a>
    </div>
}

export default Links;