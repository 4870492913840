import React from 'react';
import logo from './logo.png';
import './App.css';
import { VFXImg, VFXProvider, VFXSpan } from 'react-vfx';
import ThePowersOfTwo from './powersoftwo/ThePowersOfTwo';
import Links from './Links';

const customShader = `
precision mediump float;
uniform vec2 resolution;
uniform vec2 offset;
uniform float time;
uniform sampler2D src;
void main (void) {
    vec2 uv = (gl_FragCoord.xy - offset) / resolution;
    vec2 p = uv * 2. - 1.;
    float a = atan(p.y, p.x);
    vec4 col = texture2D(src, uv);
    float gray = length(col.rgb);
    float level = 1. + sin(a * 10. + time * 3.) * 0.2;
    gl_FragColor = vec4(col.r, col.g, col.b, col.a) * level;
}
`;

function App() {
  return (
    <VFXProvider>
      <div className="App">
        <Links />
        <VFXImg className="logo" src={logo} shader={customShader} />
        <div><VFXSpan className="text-hi">Hi!</VFXSpan></div>
        <ThePowersOfTwo />
      </div>
    </VFXProvider>
  );
}

export default App;
